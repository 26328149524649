import { getConfigFor } from './configService'
import getConfig from 'next/config'
import { checkSubstitutionDisplay } from './productSubstitutionUtils'
import { SPLIT_FEATURES } from './constants'
import { CHECKOUT_PAYMENT_METHODS } from './constants/general'
import { get } from '../lib/fetch'
import { number, string } from 'yup'
import { ORDER_TYPES } from '../lib/orderStatus'
import LocalStorageService from './LocalStorageService'

const {
  publicRuntimeConfig: { API_URL },
} = getConfig()

const updatePayloadToHandlePoaDeletedAddress = (
  payload,
  getAddressObject,
  orderToAmend
) => {
  // no address id or 0
  const { isEnabled: isDeletedAddressHandlingEnabled } = getConfigFor(
    SPLIT_FEATURES.FE_POA_DELETED_ADDRESS_HANDLING
  )

  if (!payload.addressId && orderToAmend && isDeletedAddressHandlingEnabled) {
    const addressObj = getAddressObject()
    const poaAddress = orderToAmend.address

    if (addressObj?.address || poaAddress) {
      delete payload.addressId
      payload.address = addressObj?.address || {
        ...orderToAmend.address,
        streetAddress: poaAddress.address,
        city: poaAddress.city,
        latitude: poaAddress.latitude,
        longitude: poaAddress.longitude,
        postalCode: poaAddress.postalcode,
        // metadata
        addresseeName:
          poaAddress.metaData?.FirstName || poaAddress.addresseeName,
        buildingName:
          poaAddress.metaData?.BuildingName || poaAddress.buildingName,
        floorNumber: poaAddress.metaData?.Floor || poaAddress.floorNumber,
        phoneNumber: poaAddress.metaData?.Phone || poaAddress.phoneNumber,
        unitNumber: poaAddress.metaData?.Unit || poaAddress.unitNumber,
      }
    }
  }
}

export const parseCheckoutValues = ({
  values,
  couponCodes,
  totalPriceObject,
  userType,
  isBoysBrigadeEnabled,
  userId,
  orderToAmend,
  digitalWalletToken,
  testVersion,
  amplitudeSessionId,
  getAddressObject,
}) => {
  const valuesWithCoupon = {
    ...values,
    couponCodes,
    experiment: testVersion,
    sessionId: amplitudeSessionId,
  }

  const segmentAmplitudeSessionIdString = LocalStorageService.getItem(
    'analytics_session_id'
  )
  if (segmentAmplitudeSessionIdString) {
    valuesWithCoupon.amplitudeSessionID = Number(
      segmentAmplitudeSessionIdString
    )
  }

  if (userId) {
    valuesWithCoupon.customerId = userId
  }

  if (
    valuesWithCoupon.paymentMethod ===
      CHECKOUT_PAYMENT_METHODS.CORPBILLING.value &&
    userType === 'B2B'
  ) {
    valuesWithCoupon.notes = valuesWithCoupon.purchaseReason
    delete valuesWithCoupon.purchaseReason
  }

  if (
    valuesWithCoupon.paymentMethod !== CHECKOUT_PAYMENT_METHODS.ONLINE.value &&
    valuesWithCoupon.paymentMethod !==
      CHECKOUT_PAYMENT_METHODS.GOOGLE_PAY.value &&
    valuesWithCoupon.paymentMethod !== CHECKOUT_PAYMENT_METHODS.APPLE_PAY.value
  ) {
    valuesWithCoupon.payment = [
      {
        paymentMode: valuesWithCoupon.paymentMethod,
        amount: totalPriceObject.totalAmount,
        metaData: JSON.parse(valuesWithCoupon.metaDataString || '{}'),
      },
    ]
    delete valuesWithCoupon.paymentMethod
    delete valuesWithCoupon.cardId
  }
  delete valuesWithCoupon.metaDataString

  if (userType === 'B2B') {
    valuesWithCoupon.type = 'B2B'
  } else {
    delete valuesWithCoupon.billingAddressId
  }

  if (totalPriceObject.redeemedAmount > 0) {
    valuesWithCoupon.payment = [
      {
        amount: totalPriceObject.redeemedAmount,
        paymentMode: 'LINKPOINT',
      },
    ]
  }
  if (isBoysBrigadeEnabled) {
    delete valuesWithCoupon.preferredDate
    delete valuesWithCoupon.preferredSlotId
    delete valuesWithCoupon.addressId
  }

  const { isEnabled: substitutionSwitch } = getConfigFor(
    SPLIT_FEATURES.SUBSTITUTION_SWITCH
  )

  const isProductSubstitutionEnabled =
    substitutionSwitch && checkSubstitutionDisplay(valuesWithCoupon.storeId)

  valuesWithCoupon.substitutionVariant = `substitutionVariant-${
    isProductSubstitutionEnabled ? 'B' : 'A'
  }`

  valuesWithCoupon.isCheckerEnabled = true
  valuesWithCoupon.isSellerCheckerEnabled = true

  valuesWithCoupon.isOutOfStockEnabled = true

  valuesWithCoupon.isDirectFulfilmentEnabled = true
  valuesWithCoupon.sellerTimeSlots = JSON.parse(
    valuesWithCoupon.sellerTimeSlotsString || '[]'
  )
  delete valuesWithCoupon.sellerTimeSlotsString
  valuesWithCoupon.isSellerTimeSlotEnabled = true

  if (orderToAmend) {
    valuesWithCoupon.amendableOrderReference = orderToAmend.referenceNumber
    updatePayloadToHandlePoaDeletedAddress(
      valuesWithCoupon,
      getAddressObject,
      orderToAmend
    )
  }

  if (digitalWalletToken) {
    valuesWithCoupon.token = digitalWalletToken
  }

  return valuesWithCoupon
}

export const createValidateCartPayload = ({
  values,
  appliedPromocodes,
  cartReqObj,
  orderToAmend,
  getAddressObject,
}) => {
  const { isEnabled: isReducedQuantityEnabled } = getConfigFor(
    SPLIT_FEATURES.OOS_REDUCED_QUANTITY
  )

  const cartReq = cartReqObj && cartReqObj()
  const couponCodes =
    appliedPromocodes && appliedPromocodes.map(promo => promo.coupon.couponCode)

  const requestBody = {
    addressId: values.addressId,
    cardId: values.cardId,
    cart: cartReq.cart,
    orderType: cartReq.orderType,
    storeId: cartReq.storeId,
    couponCodes,
    isCheckerEnabled: true,
    isSellerCheckerEnabled: true,
    isOutOfStockEnabled: true,
  }

  if (isReducedQuantityEnabled) {
    requestBody.isReducedQuantityEnabled = true
  }
  requestBody.isDirectFulfilmentEnabled = true
  requestBody.sellerTimeSlots = JSON.parse(values.sellerTimeSlotsString || '[]')
  requestBody.isSellerTimeSlotEnabled = true

  updatePayloadToHandlePoaDeletedAddress(
    requestBody,
    getAddressObject,
    orderToAmend
  )

  return { body: requestBody, cartReq }
}

export const searchStoreByLocation = async (location, { orderType }) => {
  const queryString = Object.keys(location)
    .map(key => `${key}=${location[key]}`)
    .join('&')
  const response = await get(
    `${API_URL}/serviceable-area?${queryString}&city=Singapore&availableStores=true&orderType=${orderType}`
  )
  return {
    deliveryFee: response.data.deliveryFee,
    availableStores: response.data.availableStores,
    storeId: response.data.store.hasPicking
      ? response.data.store.id
      : response.data.store.pickingStoreId,
    storeName: response.data.store.name,
    storeType: response.data.store.storeType,
    pickingStoreId: response.data.store.pickingStoreId,
    clientId: response.data.store.clientId,
  }
}

export const defaultProps = {
  validationSchema: {
    type: string()
      .required()
      .oneOf([ORDER_TYPES.HomeDelivery, ORDER_TYPES.B2B]),
    addressId: number(),
    cardId: number(),
    preferredDate: string(),
    preferredSlotId: number(),
    storeId: number().required(),
    paymentMethod: string()
      .required()
      .oneOf([
        CHECKOUT_PAYMENT_METHODS.ONLINE.value,
        CHECKOUT_PAYMENT_METHODS.EINVOICE.value,
        CHECKOUT_PAYMENT_METHODS.GEBIZ.value,
        CHECKOUT_PAYMENT_METHODS.CREDITTERM.value,
        CHECKOUT_PAYMENT_METHODS.APPLE_PAY.value,
        CHECKOUT_PAYMENT_METHODS.GOOGLE_PAY.value,
        CHECKOUT_PAYMENT_METHODS.CORPBILLING.value,
      ]),
    cart: string().required(),
  },
}
