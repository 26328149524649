/*
This doc maintain list name of all feature flags defined in split.io
Naming of feature in split should follow the structure `web_{Team Prefix}_featureName`
*/

const SPLIT_FEATURES = {
  BACKEND_EXP_KEY: 'web_brow_backend_driven_exp',
  SUBSTITUTION_SWITCH: 'web_checkout_substitution_switch',
  IS_PREAUTH_ENABLED: 'fe_checkout_isPreAuthEnabled',
  WEB_CONFIGURATIONS: 'web_configurations',
  DC_CONFIG: 'fe_checkout_dcConfig',
  PAYMENT_METHOD_CREDIT_TERM: 'fe_checkout_b2bPaymentConfig_creditTerm',
  PAYMENT_METHOD_GEBIZ: 'fe_checkout_b2bPaymentConfig_geBiz',
  PAYMENT_METHOD_VENDORS_GOV: 'fe_checkout_b2bPaymentConfig_vendorsGov',
  PAYMENT_METHOD_QUICKBUY: 'fe_checkout_b2bPaymentConfig_quickbuy',
  PAYMENT_SDK_APPLE_GOOGLE_PAY_ENABLED: 'fe_checkout_apple_google_pay',
  BROW_COUNTDOWN: 'fe_brow_countdown',
  B2B_TIER_CONTENT_CONFIG: 'fe_checkout_b2bTierConfig',
  BROW_RESET_PAGINATION: 'fe_brow_reset_pagination',
  DC_THRESHOLD_CONFIGS: 'fe_checkout_DCThresholdEnabled',
  INLINE_BANNERS: 'fe_brow_inln_banner',
  DC_SERVICE_FEE_ENABLED: 'fe_checkout_DCServiceFeeEnabled',
  SUBSTITUTION_DATA: 'fe_checkout_substitution_popup',
  DC_FREE_TRIAL: 'fe_account_dc_free_trial',
  SERVICE_FEES: 'fe_brow_service_fees',
  DC_FREE_TRIAL_BENEFITS: 'fe_dc_benefits',
  GIFT_ENTRY_POINT: 'fe_pay_gift_entry_point',
  VOUCHER_AVAILABLE_LABEL: 'fe_price_voucher_available_label',
  BUSINESS_PROFILE_INDUSTRIES: 'ef_business_profile_industries',
  //remote config migrations
  B2B_CONFIG: 'web_b2b_config',
  RATINGS: 'fe_brow_web_ratings',
  ES_ORDER_DURATION: 'fe_brow_es_order_duration',
  DF_CONFIGS: 'fe_checkout_df_config',
  DF_SELLER_TIMESLOT: 'fe_checkout_df_isSellerTimeSlotEnabled',
  DF_SELLER_TOOLTIP_CONFIG: 'fe_checkout_df_tool_tip_data',
  MKP_SHIPPING_TIER: 'fe_checkout_mkp_shipping_tier',
  POA_FIRST_MILESTONE: 'fe_poa_mst_1_enabled',
  POA_FIRST_POINT_ONE_MILESTONE: 'fe_poa_mst_1_point_1_enabled',
  LP_NEW_DISPLAY_LOGIC: 'fe_linkpoint_earning_display_logic_enabled',
  POA_LP_REDEEM_DISABLED: 'fe_poa_lp_redeem_disabled',
  CHECKOUT_VOUCHER_REMOVAL_BANNER: 'fe_checkout_voucher_removal_banner',
  POA_PREVENT_BULK: 'fe_checkout_bulk_order_prevention_popup_enabled',
  WEB_INVOICE_CONFIGS: 'web_invoice_configs',
  CART_MERGE_FLAG: 'web_checkout_cart_merge_flag',
  GMC_ENABLING: 'fe_checkout_gmc_isEnabled',
  EXTRA_LP_FLAG: 'isExtraLPInfoOn',
  SMART_RECOMM: 'web_brow_smart_recom',
  SHELF_LIFE: 'fe_brow_shelf_life',
  DATASCIENCE_ENABLED: 'fe_brow_datascience',
  OOS_ONBOARD_ENABLING: 'fe_oos_drop_off_onboarding_banner',
  OOS_REDUCED_QUANTITY: 'fe_checkout_oos_isReducedQuantityEnabled',
  DISABLING_LP_TOGGLE_AFTER_PLACING_ORDER:
    'mobile_pay_always_off_lp_redemption',
  SAME_DAY_NEXT_DAY: 'fe_same_day_next_day_feature',
  SAME_DAY_NEXT_DAY_NEW_LOGIC:
    'fe_sdnd_need_early_delivery_label_logic_enabled',
  SWA_HARD_NAVIGATION: 'web_swa_hard_navigation',
  COMPLEMENTARY_SWIMLANE_ENABLED: 'fe_complementary_swimlane_enabled',
  PRICING_VOUCHER_PRODUCT_DETAILS: 'fe_pricing_voucher_product_details',
  RECIPE_RATINGS_AND_REVIEWS_ENEABLED: 'fe_recipe_ratings_and_reviews_enabled',
  MARKETPLACE_DELIVERY: 'fe_brow_marketplace_delivery',
  GST_VALUE: 'fe_gst_amount',
  CART_GST_LABEL: 'cart_gst_label',
  BOYS_BRIGADE: 'fe_brow_boys_brigade',
  AMPLITUDE_PROXY: 'fe_amplitude_proxy',
  PURCHASE_ORDER_AMENDMENT_HOME_ENABLED: 'lifestyle_home_poa',
  PURCHASE_ORDER_AMENDMENT_ENABLED: 'fe_poa_mst_1_enabled',
  OG_SWIMLANE: 'og_vouchers_available_swimlane',
  OG_NEW_VOUCHER_SWIMLANE: 'fe_new_voucher_card',
  PUBLITAS: 'fe_publitas',
  PROFILE_VERIFICATION_REVAMP: 'fe_profile_verification_revamp',
  CART_FILLER: 'fe_gsr_cartfiller',
  PROMO_PRIORITY: 'fe_dis_promo_priority',
  FE_POA_HIGHLIGHT_FLAG: 'fe_poa_highlight_flag',
  CATEGORY_COLLECTION_NAVIGATION: 'fe_gsr_catnav',
  CAT_BUBBLE_ENABLED: 'fe_dis_catbubog',
  FGG50_BONUS_CERTIFICATE: 'web_fpg50_bonus_certificate',
  REPLACE_CASH_REBATES: 'web_replace_cash_rebates',
  SWIMLANE_WITH_BANNER_ENABLED: 'dis_swimbanner',
  SWIMLANE_COUNTDOWN_ENABLED: 'fe_dis_countdown',
  SEC_LANDING_PAGE: 'fe_sec_campaign',
  PRODUCT_CAMPAIGN_LABEL_ENABLED: 'fe_dis_product_campaign_label',
  ELIGLIBLE_VOUCHER_SELECTION: 'fe_eligible_voucher_selection',
  PRICE_PER_PIECE_ENABLED: 'fe_dis_priceperpiece',
  POA_DYNAMIC_TIME_ENABLED: 'fe_fulfilment_dynamic_time_enabled',
  VOUCHER_WALLET_SORTING: 'fe_voucher_wallet_sorting',
  SWA_SWIMLANE_ENABLED: 'fe_brow_promobanner',
  SIDE_SHEET: 'fe_cart_wallet_side_sheet',
  ALGOLIA_PERSONALISATION: 'fe_gsr_algoliapersonalisation',
  HEALTHIER_CHOICE_INFO_ENABLED: 'fe_hpb_healthier_choice',
  APPLIED_VOUCHERS: 'fe_applied_vouchers_group',
  FF_HIDE_ORDER_FEEDBACK: 'ff_hide_order_feedback',
  FF_ORDER_AMENDMENT: 'ff_order_amendment',
  FE_DELIVERY_NOTES: 'fe_od_deliveryNotes_enabled',
  CHECKOUT_DELIVERY_PREF_ENABLED: 'fe_delivery_preference_enabled',
  CHECKOUT_DELIVERY_PREF_FFS: 'fe_delivery_preference_ffs',
  CHECKOUT_DELIVERY_PREF_PFC: 'fe_delivery_preference_pfc',
  CART_ALCOHOL_DISCLAIMER: 'fe_cart_alcohol_disclaimer',
  CART_ALCOHOL_DISCLAIMER_CATEGORY_IDS: 'fe_cart_alcohol_disclaimer_ids',
  PAYMENT_GATEWAY_DOWN: 'fe_pricing_payment_gateway_down',
  B2B_QUICKBUY: 'fe_quickbuy',
  FIX_EMPTY_SEARCHPAGE: 'fe_gsr_fix_empty_search_result_page',
  FE_SEGMENT_BRAND_MAIN_CLICKED: 'fe_seg_brand_main_clicked',
  FE_SEGMENT_PROMO_MAIN_CLICKED: 'fe_seg_promo_main_clicked',
  FE_SEGMENT_SEARCH_COMPLETED: 'fe_seg_search_completed',
  FE_SEGMENT_CATEGORY_MAIN_CLICKED: 'fe_seg_category_main_clicked',
  NO_SWITCH_PFC: 'fe_dis_no_switch_pfc',
  FE_DISABLE_ORIGINAL_STORE_BULK_THRESHOLD_CHECK:
    'fe_cart_disable_original_store_bulk_threshold_check',
  FE_SEGMENT_ACCOUNT_CREATED: 'fe_seg_account_created',
  ACC_RECOMMENDER_FLOW: 'fe_acc_recommender_flow',
  FE_SEGMENT_IDENTIFY_CALLS: 'fe_seg_identify_calls',
  FE_SEGMENT_APP_BROWSER_OPENED: 'fe_seg_appbrowser_opened',
  FOOD_WELLNESS_ENTRY_POINT: 'fe_dis_food_and_wellness_entry_point',
  FE_DY_HEADERS_INCLUDE: 'fe_dy_headers_include',
  FF_EARNING_SECTION_ENABLED: 'ff_earning_section_enabled',
  FE_SEGMENT_OG_B2C_CHECKOUT_PLACE_ORDER_CLICKED:
    'fe_seg_og_b2c_checkout_place_order_clicked',
  FE_SEGMENT_OG_B2B_CHECKOUT_PLACE_ORDER_CLICKED:
    'fe_seg_og_b2b_checkout_place_order_clicked',
  FE_SEGMENT_OG_CHECKOUT_AMENDMENT_CLICKED: 'fe_seg_checkout_amendment_clicked',
  FE_GSR_MIGRATION_WTYL: 'fe_gsr_migration_wtyl',
  FE_POA_DELETED_ADDRESS_HANDLING:
    'fe_checkout_poa_deleted_address_handling_enabled',
  FE_ELIGIBLE_ITEM_ADD_TO_CART_ENABLED: 'fe_eligible_item_add_to_cart_enabled',
  FE_RETRIGGER_IMPRESSION: 'fe_dis_retrack_impressions',
  FE_NEW_CUST_PROFILE_DATA_SOURCE:
    'web_identity_new_customer_profile_data_source',
  FE_GT_REC_WTYL: 'fe_gt_rec_wtyl',
  FE_GT_REC_FBT: 'fe_gt_rec_fbt',
  FE_GT_REC_CAT: 'fe_gt_rec_category',
  FE_GT_REC_PDBN: 'fe_gt_rec_pdbn',
  FE_GT_REC_WS: 'fe_gt_rec_ws',
  FE_GT_REC_R4U: 'fe_gt_rec_r4u',
  SWA_BANNER: 'fe_dis_swa_banner',
  FE_POA_API_URL: 'is_new_Poa_orderAPI_path_enabled',
  FE_OG_QUANTITY_INPUT_VALIDATION_FIX: 'fe_og_qty_input_validation_fix',
  CARD_SIGN_DYNAMIC_FIELDS: 'fe_pricing_payment_cardsign_dynamic_fields', // use in the payment sdk
  TRUST_PLACEMENT_ENABLED: 'fe_pricing_payment_trust_placement_enabled', // use in the payment sdk
  GOOGLE_PAY_3DS: 'fe_pricing_payment_google_pay_3ds_only_web', // use in the payment sdk
  FE_DIS_PDP_VOUCHERS: 'fe_dis_pdp_vouchers',
}

module.exports = {
  SPLIT_FEATURES,
}
